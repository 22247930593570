import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { datadogRum } from '@datadog/browser-rum';

export default function App({ Component, pageProps }: AppProps) {
  
  if (process.env.NODE_ENV==='production') {
    datadogRum.init({
        applicationId: 'c958a017-3bcd-4122-a381-66d9ab3b2b0a',
        clientToken: 'pub78077bae9f97fe74929ceeaeb7d5a610',
        site: 'datadoghq.com',
        service:'carlesjavierre',
        env: "prod",
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input',
        enableExperimentalFeatures: ["clickmap", "feature_flags"],
    });
        
    // datadogRum.startSessionReplayRecording();
  }

  return <Component {...pageProps} />
}
